<template>
  <div class="settings">
    <div :class="'mobileNavBar ' + deviceType">

      <div @click="routeBack('/', false)" class="goBack" style="">
        <span  v-if="deviceType == 'ios'">
          <span style="height: 22px;position: absolute; margin-top: -1px; line-height: 22px; color: #007AFF; font-size: 17px; margin-left: 30px; font-weight: 400;"></span>
          <img style="height: 22px; position: absolute;left: 12px; opacity: 1; line-height: 22px;"  :src="$root.CDN_SITE + 'icon/ios_back_laci.svg'">
        </span>
        <span  v-else>
           <img style="height: 18px; padding-left: 16px; line-height: 18px; " :src="$root.CDN_SITE + 'icon/ic_arrow_back.svg'">
        </span>
      </div>
      <div class="title" style=" color: #2d3640;">
        Satışlarım
      </div>
      <div class="options" >

      </div>

    </div>
    <div :class="'underAppBar ' + deviceType" ></div>

    <div :style="{ 'min-height': `${windowHeight - 110}px` }">

      <loader v-if="!pageLoaded"  style="margin-top: 40vh;"></loader>
      <div v-else>

        <div class="content-ustband-mobile"
             style="background-color: #ffffff; height: 60px; display: flex;align-content: center; align-items: center; border-bottom: 1px solid #eaedf2;">
          <div style="justify-content: center; align-items: center; display: flex; flex: 1;">


            <template>
              <select v-model="status" @change="retrieveOrders(type, status, 0)" name="status" style="" class="super-drop-tek-cizgi">
                <option :value="null">Hepsi</option>
                <option :value="1">Yeni</option>
                <option :value="2">Aktif</option>
                <option :value="3">Onay Bekliyor</option>
                <!--
               ARTIK STATUSLER 4'e KADAR REVIEWED STATUS E KATILIYOR
               <option :value="4">Tamamlanmış</option>
                <option :value="5">Değerlendirilmiş</option>
               -->
                <option :value="4">Tamamlanmış</option>
                <option :value="-1">İptal Edilmiş</option>
              </select>
            </template>
          </div>
        </div>

        <div v-if="bodyLoaded">
          <div v-if="username" style="margin: auto; padding: 20px; text-align: center;" >Sadece {{username}} ile aranızdaki siparişler getiriliyor. <span style="color: rgb(230, 50, 98);" @click="removeUsernameFilter()">Temizle</span></div>
          <div v-if="orders.length">
            <router-link v-for="order in orders" :key="order.order_id" class=" hover" :to="`/orders/${order.order_id}`" style=" height:40px; width:100%; padding-left: 0; padding-top: 12px; padding-bottom: 12px; display: flex;align-content: center; align-items: center;border-top:none; border-left: none; border-right: none;">
              <div class="chatrow" style=" align-items: center; flex:1; justify-content: space-between; padding-right: 10px; padding-left: 10px">
                <div style="flex: 1; ">
                  <img style="height: 32px" :src="order.image_url">
                </div>
                <div style=" font-size:10px;flex: 5; max-width: 60%;  color: #2d3640; padding-left: 10px;">
                  Ben, {{ order.title }}
                </div>
                <div style="flex: 1; font-size: 12px; color: #2d3640; text-align: right;">
                  <router-link :to="'/'+ order.kim.username" style="color: #fd4056">{{ order.kim.username }}</router-link>
                </div>
                <div style="flex: 1; font-size: 12px; color: #2d3640; text-align: right; ">
                  {{ order.payed_at | customDateFormat('dd MMM yyyy') }}
                </div>
                <!-- şimdilik mobilde yok
                <div v-if="order.due_on" style="color: #2d3640;flex: 4; margin-left: 25px; font-size: 12px; min-width: 70px;">
                  {{ order.due_on | customDateFormat('dd MMM yyyy') }}
                </div>
                <div v-else style="flex: 4; margin-left: 25px; font-size: 12px; min-width: 70px;">
                </div>
                <div style="color: #2d3640;flex: 3; margin-left: 25px;font-size: 12px; min-width: 40px;  ">
                  {{ order.total }}
                </div>
                <div style="color: #2d3640;flex: 3; margin-left: 25px;font-size: 12px; min-width: 100px;  ">
                  {{ order.statusText }}
                </div>
                -->
              </div>
            </router-link>
            <div v-if="!seemoreLoader && seemorediv" @click="retrieveOrdersForSeemoreButton(type, status, offset)"  style="cursor: pointer; margin-top:20px;font-size: 14px; font-weight: 600;text-align: center; color: #2d3640;">
              Daha fazla gör +
            </div>
            <loader v-else-if="seemoreLoader"></loader>
          </div>
          <div v-else style="margin: 30px auto; padding: 20px; text-align: center;">
            <p>Aradığın kriterde siparişin bulunmamaktadır.</p>
          </div>
        </div>
        <loader v-else></loader>

        <div class="hiddendiv"></div>
        <div class="hiddendiv"></div>
      </div>
    </div>
  </div>
</template>

<script>
  import WorkstationNav from "../../navComponent";

  export default {
    name: "src-pages-body-workstation-sales-v2_mobile",
    components: {
      WorkstationNav
    },
    data () {
      return {
        pageLoaded: false,
        bodyLoaded: true,
        offset: 0,
        totalCount: null,
        seemorediv: false,
        seemoreLoader: false,
        orders: [],
        type: null,
        status: null,
        limit: 10,
        username: null
      }
    },

    methods: {
      retrieveOrders(type = "selling", status, offset, username = this.username) {
        this.bodyLoaded = false;
        this.offset = 0;
        this.orders = [];
        this.api.orders.retrieveOrders(type, status, this.limit, offset, username)
          .then(({data}) => {
            let result = data;

            if (result.success) {
              this.pageLoaded = true;
              this.bodyLoaded = true;
              this.offset += this.limit;
              this.orders = this.orders.concat(result.data.orders);
              this.type = type;
              this.status = status;
              this.totalCount = result.data.orderCount;
              this.seemorediv = result.data.seemorediv;
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },
      retrieveOrdersForSeemoreButton(type = "selling", status, offset, username = this.username) {
        this.seemoreLoader = true;
        this.api.orders.retrieveOrders(type, status, this.limit, offset, username)
          .then(({data}) => {
            let result = data;

            if (result.success) {
              this.seemoreLoader = false;
              this.pageLoaded = true;
              this.bodyLoaded = true;
              this.offset += this.limit;
              this.orders = this.orders.concat(result.data.orders);
              this.type = type;
              this.status = status;
              this.totalCount = result.data.orderCount;
              this.seemorediv = result.data.seemorediv;
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },

      removeUsernameFilter() {
        this.username = null;
        this.$router.push(`/panel/satislar`);
      }
    },

    watch: {
      'route.query.username': function () {
        this.username = (this.route.query.username && this.route.query.username !== 'undefined' && this.route.query.username !== "null") ? this.route.query.username : null;
        this.retrieveOrders("selling", null, 0, this.username);
      }
    },

    created() {
      this.username = (this.route.query.username && this.route.query.username !== 'undefined' && this.route.query.username !== "null") ? this.route.query.username : null;
      this.retrieveOrders("selling", null, 0, this.username);
    }
  }
</script>

<style scoped>

  .chatrow{
    display: flex; align-items: center;
  }

  .content-white.hover{
    cursor: pointer;
  }
  .content-white.hover:hover{
    background-color: #F5F6F8;
    color: inherit!important;
    text-decoration: none;
  }
  .super-drop-tek-cizgi {
    -webkit-appearance: none;
    background: url(https://gcdn.bionluk.com/site/icon/aws/icon_alt_ok_1.svg) no-repeat right;
    background-position-x: 140px;
    -moz-appearance: none;
    min-width: 160px;
    appearance: none;
    border-radius: 2px;
    font-family:'sofia-pro';

    position: absolute;;


    background-color: #fefdfd;
    color: #939c9c;

    border:none;
    border-bottom: solid 1px #bfc8d2;

    font-size: 14px;
    padding: 4px 5px 6px;
  }

  .super-drop-tek-cizgi:hover{

    border:none;
    border-bottom: solid 1px #2d3640;
    color: #2d3640;
    cursor: pointer;
  }


  .menu-list {
    line-height: 1.25;
  }

  ul {
    list-style: none;
  }

  li {
    display: list-item;
    text-align: -webkit-match-parent;
  }

  li:hover {
    display: list-item;
    text-align: -webkit-match-parent;
    color: #2d3640;
  }

  .menu-list li {
    cursor: pointer;
    border-radius: 2px;
    color: #4a4a4a;
    display: block;
    padding: .5em .75em;
  }
  .menu-list li:hover {
    border-radius: 2px;
    color: #2d3640;
    display: block;
    padding: .5em .75em;
    text-decoration: none;
  }

</style>
